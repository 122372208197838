.preview-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.preview-box {
    position: relative;
    cursor: pointer;
}

.remove-icon {
    text-align: end;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 18px;
    height: 18px;
    background-color: rgba(255, 45, 85);
    border-radius: 50px;
    cursor: pointer;
    transition: transform 0.3s ease;
    padding: 0.2rem;
    font-weight: bold;
    color: #fff;
    z-index: 10;
}

.remove-icon:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}

.preview-image {
    width: 620px;
    height: 280px;
    border-radius: 10px;
    object-fit: cover;
    transition: border 0.3s ease;
}

.preview-image:hover {
    border: 0.1rem solid rgb(107, 178, 255);
    transition: border 0.3s ease;
}

.preview-image-disabled {
    border: 0.1rem solid rgba(255, 45, 85);
}

.preview-image-active {
    border: 0.1rem solid rgb(0, 122, 255);
}

.video-placeholder {
    border-radius: 3px;
    margin-top: 0.5rem;
}

.add-more-videos {
    width: 120px;
    height: 120px;
    border: 0.1rem solid rgba(255, 45, 85);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    cursor: pointer;
    transition: font-size 0.3s ease;
}

.add-more-videos:hover {
    font-size: 3rem;
    transition: font-size 0.3s ease;
}
